$filterbrightness: brightness(80%);

.notFound_container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 80vh;
  width: auto;
  padding: 1.6rem;

  img {
    width: auto;
    height: 35%;
  }

  h1 {
    font-size: 6rem;
    font-weight: lighter;
  }

  h2 {
    margin-top: 3rem;
    filter: $filterbrightness;
    font-size: 3rem;
  }

  p {
    filter: $filterbrightness;
    font-size: 1.4rem;
    margin-bottom: 10rem;
  }
}
