@import '../../global_variables.scss';

.dropdown {
  position: relative;

  select {
    position: relative;
    display: inline-block;
    outline: none;
    padding: 1rem 3.2rem 1rem 1.4rem;
    font-size: 1.4rem;
    font-weight: 700;
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    cursor: pointer;
    line-height: 1.1;
    color: $prime_gray;
    border: $border;
    border-radius: 0.2em;
    width: 18rem;
    margin-bottom: 1.5rem;
    margin-left: 1rem;
    background: url(../assets/icons/expandMore.svg) no-repeat right;
    -webkit-appearance: none;
    background-position-x: 96%;
    background-color: $white;
    box-shadow: $shadow;
  }

  select::-ms-expand {
    display: none;
  }

  select:hover,
  select:focus {
  }

  select:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
