// Global CSS variabels
$site_bg: #ecf0f1;
$white: #fcfcfc;
$dark_text: #050609;
$prime_dark: #2f4050;
$prime_gray: #5f6368;
$component_bg: #d6dcdd;
$component_bg_light: #f1f1f1;
$shadow: /* 0 7px 30px -10px rgba(150, 170, 180, 0.5) */ none;
$component_shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
$border: 1px solid $component_bg;

// PS colors
$ps_red: #ff5a3a;
$ps_blue: #283fff;
$ps_black: #1b1b1b;
$ps_gray: #f1ece8;
$ps_blue_light: #8aaeff;
$ps_blue_dark: #242d65;
$ps_orange: #ea965d;

// Stats colors
$stats_green: #1abc9c;
