@import 'global_variables.scss';

#statCount {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2rem;
  color: $prime_dark;
}

#statTotal {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  color: $prime_dark;
}

.page_title {
  color: $prime_dark;
}

#StatLabel {
  font-size: 1rem;
  line-height: 1.9rem;
  color: $prime_dark;
  opacity: 0.5;
}

#statTotal {
  margin-top: 2rem;
  color: $prime_dark;
}
