@import '../../../../../global_variables.scss';

.optimal_teaser_charts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: $prime_dark;

  .flex-item-left {
    width: 50%;
  }

  .flex-item-right {
    width: 50%;
  }

  .title_container {
    display: flex;
    flex-direction: column;
  }

  .title_container > h2 {
    margin-top: 1.6rem;
    margin-bottom: 2.5rem;
    /* margin-left: auto;
    margin-right: auto; */
  }
}

// Recharts class names
.recharts-wrapper {
  margin: 0 auto;
}

.recharts-responsive-container {
  margin-left: auto;
  margin-right: auto;
}
