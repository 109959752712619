@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');

@font-face {
  font-family: 'Austin Deck';
  src: url('https://static.partnerstudio.io/mab/fonts/AustinNewsDeck-Bold-Web.woff2')
    format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Austin Deck';
  src: url('https://static.partnerstudio.io/mab/fonts/AustinNewsDeck-Extrabold-Web.woff2')
    format('woff2');
  font-weight: 700;
}

// Fonts E24:
@font-face {
  font-family: 'E24 Display';
  src: url('https://static.partnerstudio.io/mab/fonts/E24_Display-Medium-1.2.1-web.woff2')
    format('woff2');
}

// Fonts Storby:
@font-face {
  font-family: 'Publico Headline';
  src: url('https://static.partnerstudio.io/mab/fonts/PublicoHeadline.woff2')
    format('woff2');
}

@font-face {
  font-family: 'TiemposHeadlineMedium';
  src: url('https://static.partnerstudio.io/mab/fonts/TiemposHeadlineWeb-Medium.woff2')
    format('woff2');
}

.preview-client {
  display: none;
}

.scaleContainer {
  display: flex;
  flex-direction: row;
  transform: scale(0.78);
  transform-origin: 0 0;
  width: calc(100% / 0.78);
  margin-top: 3rem;
  margin-bottom: -12rem;
  overflow-x: auto;
  overflow-y: hidden;
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1920px) {
  .scaleContainer {
    display: flex;
    flex-direction: row;
    transform: scale(0.56);
    transform-origin: 0 0;
    width: calc(100% / 0.56);
    margin-bottom: -35rem;
  }
}

.teaser-wrapper {
  width: fit-content;
  min-width: fit-content;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-left: 2rem;
  margin-bottom: 2rem;

  #teaserDate {
    font-size: 2rem;
    padding-bottom: 1.6rem;
  }

  /* 
  * Fix dr. front styling to be responsive
  */
  $start: 0;
  $end: 256;

  @for $i from $start through $end {
    .drfront .df-fs-#{$i} {
      font-size: calc(0.95px * #{$i}) !important;
    }
    .drfront .df-lh-#{$i} {
      line-height: calc(0.95px * #{$i}) !important;
    }
    .drfront .df-ls-#{$i} {
      letter-spacing: calc(0.95px * #{$i}) !important;
    }
    .drfront .df-ls-#{$i} {
      letter-spacing: calc(0.95px * #{$i}) !important;
    }
    .drfront .df-ws-#{$i} {
      word-spacing: calc(0.95px * #{$i}) !important;
    }
  }

  $start_neg: 0;
  $end_neg: 64;

  @for $j from $start_neg through $end_neg {
    .drfront .df-ls--#{$j} {
      letter-spacing: calc(0.95px * -#{$j}) !important;
    }
    .drfront .df-ws--#{$j} {
      word-spacing: calc(0.95px * -#{$j}) !important;
    }
  }

  a {
    text-decoration: none;
  }
}

.df-img-container {
  width: 100% !important;
}

.df-img-container-inner {
  .df-article-img,
  image,
  img {
    width: 100% !important;
    height: auto !important;
  }
}

.drfront {
  /* CSS Reset */

  /* Default CSS */

  line-height: 1;
  color: #464647;
  width: 400px;
}
.drfront div,
.drfront span,
.drfront applet,
.drfront object,
.drfront iframe,
.drfront h1,
.drfront h2,
.drfront h3,
.drfront h4,
.drfront h5,
.drfront h6,
.drfront p,
.drfront blockquote,
.drfront pre,
.drfront a,
.drfront abbr,
.drfront acronym,
.drfront address,
.drfront big,
.drfront cite,
.drfront code,
.drfront del,
.drfront dfn,
.drfront em,
.drfront font,
.drfront img,
.drfront ins,
.drfront kbd,
.drfront q,
.drfront s,
.drfront samp,
.drfront small,
.drfront strike,
.drfront strong,
.drfront sub,
.drfront sup,
.drfront tt,
.drfront var,
.drfront dl,
.drfront dt,
.drfront dd,
.drfront ol,
.drfront ul,
.drfront li,
.drfront fieldset,
.drfront form,
.drfront label,
.drfront legend,
.drfront table,
.drfront caption,
.drfront tbody,
.drfront tfoot,
.drfront thead,
.drfront tr,
.drfront th,
.drfront td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
  line-height: normal;
}

.drfront .df-article-content .df-blk,
.drfront .df-article h3 a,
.drfront .df-article h3 {
  color: #464647 !important;
  font-size: calc(100vw / 70) !important;
  line-height: 1.1em;
  font-family: 'Austin Deck', 'Times New Roman', serif !important;
}

.drfront .df-article h4 a,
.drfront .df-article h4 {
  color: #636465 !important;
  font-size: 0.9vw;
  line-height: auto;
  font-family: 'Inter', 'Helvetica Neue', Helvetica, sans-serif !important;
}
