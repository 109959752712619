@import '../../global_variables.scss';

.card_wrapper {
  background-color: $white;
  padding: 2.5rem;
  margin-top: 1.6rem;
  margin-bottom: -2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
  color: $dark-text;
  border: $border;
}

.card_wrapper > * {
  margin-bottom: 1rem;
}
