@import '../../global_variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  position: relative;

  h3 {
    font-size: 1.8rem;
    font-weight: 400;
    color: $prime_gray;
    margin-top: 0.6rem;
  }
}
