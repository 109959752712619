@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');

@font-face {
  font-family: 'Austin Deck';
  src: url('https://static.partnerstudio.io/mab/fonts/AustinNewsDeck-Bold-Web.woff2')
    format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Austin Deck';
  src: url('https://static.partnerstudio.io/mab/fonts/AustinNewsDeck-Extrabold-Web.woff2')
    format('woff2');
  font-weight: 700;
}

// Fonts E24:
@font-face {
  font-family: 'E24 Display';
  src: url('https://static.partnerstudio.io/mab/fonts/E24_Display-Medium-1.2.1-web.woff2')
    format('woff2');
}

// Fonts Storby:
@font-face {
  font-family: 'Publico Headline';
  src: url('https://static.partnerstudio.io/mab/fonts/PublicoHeadline.woff2')
    format('woff2');
}

@font-face {
  font-family: 'TiemposHeadlineMedium';
  src: url('https://static.partnerstudio.io/mab/fonts/TiemposHeadlineWeb-Medium.woff2')
    format('woff2');
}

.no-teaser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 4rem;
    opacity: 0.6;
    margin-bottom: -2rem;
  }
}

.teaser {
  display: flex;
  justify-content: center;
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);

  /* 
  * Fix dr. front styling to be responsive
  */
  $start: 0;
  $end: 256;

  @for $i from $start through $end {
    .drfront .df-fs-#{$i} {
      font-size: calc(0.95px * #{$i}) !important;
    }
    .drfront .df-lh-#{$i} {
      line-height: calc(0.95px * #{$i}) !important;
    }
    .drfront .df-ls-#{$i} {
      letter-spacing: calc(0.95px * #{$i}) !important;
    }
    .drfront .df-ls-#{$i} {
      letter-spacing: calc(0.95px * #{$i}) !important;
    }
    .drfront .df-ws-#{$i} {
      word-spacing: calc(0.95px * #{$i}) !important;
    }
  }

  $start_neg: 0;
  $end_neg: 64;

  @for $j from $start_neg through $end_neg {
    .drfront .df-ls--#{$j} {
      letter-spacing: calc(0.95px * -#{$j}) !important;
    }
    .drfront .df-ws--#{$j} {
      word-spacing: calc(0.95px * -#{$j}) !important;
    }
  }

  a {
    text-decoration: none;
  }
}
