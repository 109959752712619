@import '../../../../global_variables.scss';

$border_radius: 0.2em;

.table_title {
  text-align: center;
  padding: 0 0 1.6rem 0;
}

.table {
  table {
    width: 100%;
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    color: $prime_dark;
  }

  /* top-left border-radius */
  table tr:first-child th:first-child {
    border-top-left-radius: $border_radius;
  }

  /* top-right border-radius */
  table tr:first-child th:last-child {
    border-top-right-radius: $border_radius;
  }

  /* bottom-left border-radius */
  table tr:last-child td:first-child {
    border-bottom-left-radius: $border_radius;
  }

  /* bottom-right border-radius */
  table tr:last-child td:last-child {
    border-bottom-right-radius: $border_radius;
  }

  td,
  th {
    padding: 1rem;
    font-size: 1.4rem;
  }

  tr:nth-child(even) {
    background-color: $component_bg_light;
  }

  tr:hover {
    background-color: #dcdcdc;
  }

  th {
    text-align: left;
    padding: 1.2rem;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2.4rem;
    color: $prime_dark;
  }
}

td,
tr,
th {
  cursor: pointer;
}
