@import 'global_variables.scss';

.label,
h3 {
  color: $prime_dark;
  font-size: 1.2rem;
  line-height: 1rem;
}

.tooltip_header {
  margin-bottom: 1.5rem;
}

.hide_label {
  display: none;
}

.recharts-tooltip-wrapper,
.recharts-tooltip-wrapper-right,
.recharts-tooltip-wrapper-top {
  outline: none !important;
}
