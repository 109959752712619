@import '../../global_variables.scss';

.sidebar_icon-open {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transition: transform 1.2s ease;
}

.sidebar_icon {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transition: transform 1.2s ease;
}

.toggle_wrapper {
  position: absolute;
  margin-top: 2rem;
}

.toggle_icon {
  opacity: 0.8;
}

.toggle_icon:hover {
  cursor: pointer;
  opacity: 1;
}

.Sidebar {
  height: 100%;
  width: 100%;
  background-color: $prime_dark;
  overflow: hidden;
  /* box-shadow: 3px 0px 10px -1px rgba(42, 53, 63, 0.4);
  -webkit-box-shadow: 3px 0px 10px -1px rgba(42, 53, 63, 0.4);
  -moz-box-shadow: 3px 0px 10px -1px rgba(42, 53, 63, 0.4); */

  .brandLogo {
    display: block;
    margin: auto;
    padding-top: 2.6rem;
    opacity: 0.8;
  }

  .navlink {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 3.2rem;
    color: $white;
    list-style: none;
    text-decoration: none;
  }

  .nav_item_wrapper {
    padding: 0;
    height: auto;
    width: 100%;
    margin-top: 15rem;

    .nav_row {
      list-style-type: none;
      width: 100%;
      height: 6rem;
      margin: 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
      padding-left: 2.5rem;

      #icon {
        width: auto;
        height: 35%;
        flex: 20%;
        display: grid;
        place-items: center;
      }

      #title {
        flex: 70%;
      }
    }

    .nav_row--collapse {
      @extend .nav_row;
      height: 6rem;
      padding-left: 0rem;

      #icon {
        width: 20px;
        padding-right: 1.5rem;
      }

      .mantine-Tooltip-root {
        padding-left: 1.5rem;
      }
    }

    .sub_row {
      margin-left: 2rem;
    }

    #active_nav {
      background-color: #293846;
      opacity: 1;
      border-left: 2px solid $white;
    }

    .nav_row:hover {
      cursor: pointer;
      background-color: #293846;
      opacity: 1;
    }

    &--collapse {
      @extend .nav_item_wrapper;
      margin-top: 18.5rem;
    }
  }

  @media only screen and (max-width: 1920px) {
    .nav_item_wrapper {
      margin-top: 8rem;

      &--collapse {
        margin-top: 11.5rem;
      }
    }
  }
}
