@import 'global_variables.scss';

.autocomplete {
  position: relative;

  ul {
    position: absolute;
    z-index: 1000 !important;
    max-height: 600px;
    width: 49.8rem;
    overflow: auto;
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-left: 2rem;
    margin-top: -1rem;
    background-color: $white;
    border-radius: 0.2rem;
    box-shadow: $component_shadow;
    border: $border;
  }
  &::before {
    content: '';
  }

  li {
    padding: 1rem 1.4rem;
    font-size: 1.4rem;
    cursor: pointer;
  }

  li:hover {
    background-color: $component_bg;
    color: $prime_dark;
    font-weight: 700;
  }

  input[type='search'],
  #completeInput {
    position: relative;
    display: inline-block;
    outline: none;
    padding: 1rem 3.5rem;
    font-size: 1.4rem;
    font-weight: 700;
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    cursor: pointer;
    line-height: 1.1;
    color: $prime_gray;
    border: $border;
    border-radius: 0.2em;
    width: 50rem;
    margin-bottom: 1.5rem;
    margin-left: 2rem;
    background-color: $white;
    box-shadow: $shadow;
    box-sizing: border-box;
    /* Serach icon left */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="%235f6368" d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"/></svg>');
    background-size: 0.7em;
    background-repeat: no-repeat;
    background-position: left 1em center;
  }

  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: -2rem;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="%235f6368" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>');
    background-size: 0.6em;
    background-repeat: no-repeat;
    background-position: right center;
    font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    cursor: pointer;
  }
}
