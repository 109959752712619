@import 'global_variables.scss';

.stat-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  margin-left: 2rem;
  width: 100%;

  .ctr {
    padding-left: 2rem;
    font-size: 2.2rem;
    font-weight: 700;
    color: $prime_dark;
  }

  .ctr-id {
    padding-left: 2rem;
    font-size: 2rem;
    font-weight: 500;
    color: $prime_gray;
  }
}

hr {
  margin: 0 2rem 2.5rem 2rem;
  opacity: 0.5;
}

.modalBtn {
  margin-right: 4rem;
  width: 5rem;
  height: 5rem;
}

.modalTitle {
  cursor: pointer;
}

.statCard {
  padding: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;

  h4 {
    padding-bottom: 0.5rem;
    color: $prime_gray;
    font-size: 1.2rem;
  }

  h1 {
    color: $prime_dark;
    font-size: 2.2rem;
  }
}

.modalContent {
  padding: 2rem;
  margin-top: -2rem;
}
