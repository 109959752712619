@import '../../global_variables.scss';

.actionBtn {
  margin-left: 2rem;
  border: $border;
  border-radius: 0.2em;
  cursor: pointer;
  background-color: $white;
  box-shadow: $shadow;
  width: 3.7rem;
  height: 3.7rem;
}
.actionBtn:hover {
  background-color: $component_bg-light;
}
