@import 'global_variables.scss';

.statistics {
  display: flex;
  flex-direction: column;

  #page_title {
    margin-left: 1rem;
    margin-bottom: -3rem;
    margin-top: 2rem;
    color: $prime_dark;
  }

  h2 {
    margin-top: 1.5rem;
    margin-bottom: -3.5rem;
    margin-left: 1rem;
    color: $prime_dark;
  }
}

.toolbar {
  display: flex;
  flex-direction: row;
}
