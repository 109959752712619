@import 'subpg_wrapper';
@import 'global_variables.scss';

#page_title {
  margin-left: 1rem;
  margin-bottom: -3rem;
  margin-top: 2rem;
  color: $prime_dark;
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
