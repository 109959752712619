@import 'pg_wrapper';

.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-template-columns: 25rem;
  height: 100vh;
  transition: 0.2s ease;
}

.sideBar_container {
  grid-area: 1 / 1 / 2 / 2;
  cursor: pointer;
}

.overview > * {
  padding-bottom: 1rem;
  &:last-child {
    padding-bottom: 0;
  }
}

.dashboard_container {
  grid-area: 1 / 2 / 2 / 3;
  align-items: center;
  grid-auto-flow: row;
  grid-auto-rows: 25%;
  grid-template-columns: unset;
  grid-template-rows: unset;
  overflow: scroll;
  padding-bottom: 5rem;
  padding-right: 1rem;
}
