@import 'global_variables.scss';

.statistics_section {
  display: flex;
  flex-direction: row;

  h1 {
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 2.4rem;
    color: $prime_dark;
    margin-bottom: -0.8rem;
  }

  h4 {
    font-size: 1.2rem;
    line-height: 1.9rem;
    color: $prime_dark;
    opacity: 0.5;
    margin-bottom: 2rem;
  }
}
