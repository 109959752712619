@import 'global_variables.scss';

.label_s,
h3 {
  color: $prime_dark;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-top: -6px;
}

.tooltip_header_s {
  margin-bottom: 1.5rem;
}

.hide_label_s {
  display: none;
}
